import { render, staticRenderFns } from "./areas.template.pug?vue&type=template&id=668c12f2&lang=pug&external"
import script from "./areas.script.js?vue&type=script&lang=js&external"
export * from "./areas.script.js?vue&type=script&lang=js&external"
import style0 from "./areas.style.css?vue&type=style&index=0&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports