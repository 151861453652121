//
import PProviderService from '@/services/api/Provider/PProvider'
// import RulesService from '@/services/rules'

//
export default {
  name: 'v-view-provider',
  data() {
    return {
      busy: {
        provider: false,
        update: false,
      },
      dialogs: {
        update: false,
      },
      pAreas: [
        {
          text: 'Administración',
          value: 1,
        },
        {
          text: 'Técnica',
          value: 2,
        },
        {
          text: 'Comercial',
          value: 3,
        },
      ],
      pProvider: {},
      pProviderUpdate: {},
      pProviderReset: {},
    }
  },
  methods: {

    /**
     * 
     */
    handleError(payload) {
      PProviderService.translate(payload).then(this.$toast.error)
    },

    /**
     * 
     */
    async handleFormReset() {
      this.busy.provider = true
      this.pProvider = await PProviderService.getById(this.$provider.id)
      this.busy.provider = false
    },

    /**
     * 
     */
    async handleUpdateClick(area) {
      this.dialogs.update = true
      this.pProviderReset = { ...area }
      this.pProviderUpdate = { ...area }
    },

    /**
     * 
     */
    async handleUpdateReset() {
      this.pProviderUpdate = this.pProviderReset
    },

    /**
     * 
     */
    async handleUpdateSubmit() {
      this.busy.update = true
      await PProviderService.areas(this.pProvider, [this.pProviderUpdate]).then(this.handleSuccess).catch(this.handleError).finally(this.handleFormReset)
      this.busy.update = false
    },

    /**
     * 
     */
    handleSuccess(payload) {
      PProviderService.translate({ response: payload }).then(this.$toast.success)
      this.dialogs.update = false
    },

  },
  created() {
    this.handleFormReset()
  },
}